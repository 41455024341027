/** @module lib/utils/array */
/**
 * Retourne true si arg est un tableau de longueur nulle
 * @param arg
 */
export const isEmptyArray = (arg: unknown[]): boolean => Array.isArray(arg) && arg.length === 0

/**
 * Retourne le dernier élément d’un tableau
 * @param {Array} list
 * @return {*} Le dernier élément, ou undefined si list n’était pas un tableau non vide
 */
export function lastElement<T> (list: T[]): T | undefined {
  return (Array.isArray(list) && list.length > 0) ? list[list.length - 1] : undefined
}

/**
 * Déplace un élément dans le tableau (qui est modifié)
 * @param arr
 * @param oldIndex
 * @param newIndex
 */
export function arrayMove (arr: any[], oldIndex: number, newIndex: number): void {
  if (newIndex >= arr.length || newIndex < 0) throw TypeError(`nouvel index invalide ${newIndex}`)
  if (oldIndex >= arr.length || oldIndex < 0) throw TypeError(`ancien index invalide ${oldIndex}`)
  // on vire l’élément qui bouge
  const elt = arr.splice(oldIndex, 1)[0]
  // et on l’insère à sa nouvelle place
  arr.splice(newIndex, 0, elt)
}

/**
 * Fusionne des tableaux et dédoublonne les éléments
 * @param {...unknown} args Autant de tableaux ou d’éléments qu’on veut
 * @return {unknown[]} Un tableau qui dedup tous les éléments des tableaux (ou éléments) passés en arguments
 */
export function dedup (...args: Array<unknown>) {
  const listeVide: Array<unknown> = []
  const ar: Array<unknown> = listeVide.concat(...args)
  const s = new Set(ar)
  return Array.from(s)
}
