export const DEFAULT_OFFSET = 100

export interface PositionSerialized {
  x: number
  y: number
}
export type PositionJson = PositionSerialized

/**
 * Une position avec propriétés x & y qui sont des number finis (0 par défaut)
 */
class Position {
  x: number
  y: number
  /**
   * Une position avec propriétés x & y qui sont des number finis (0 par défaut)
   */
  constructor ({ x = 0, y = 0 }: Partial<Position> = {}) {
    /** @type {number} */
    this.x = Number.isFinite(x) ? x : 0
    /** @type {number} */
    this.y = Number.isFinite(y) ? y : 0
  }

  translate ({ x = 0, y = 0 }: Partial<Position>): void {
    if (!Number.isFinite(y) || !Number.isFinite(x)) throw Error('translation impossible')
    this.x += x
    this.y += y
  }

  serialize (): PositionSerialized {
    return { x: this.x, y: this.y }
  }

  toJSON (): PositionJson {
    return { x: this.x, y: this.y }
  }
}

export default Position
