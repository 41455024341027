/** @module lib/utils/validators */

import Graph, { GraphValues } from 'src/lib/entities/Graph'

/**
 * Vérifie que c’est bien un tableau avec le bon contenu
 * @param value
 * @param {string} [label]
 * @param {Object} [options]
 * @param {boolean} [options.notEmpty] passer true pour refuser les tableaux vides
 * @param {string} [options.arrayOf] passer un type pour vérifier que tous les éléments sont de ce type
 * @param {boolean} [options.allDef] passer true pour refuser les tableaux avec un élément null|undefined
 * @throws {TypeError} Si y’a un pb
 * @return {void} Si c’est ok
 */
export function checkArray (value: unknown, label = 'valeur', {
  notEmpty = false,
  arrayOf = '',
  allDef = false
} = {}): void {
  if (!Array.isArray(value)) throw TypeError(`${label} n’est pas un array`)
  if (notEmpty && (value.length === 0)) throw TypeError(`${label} est un array vide`)
  if (arrayOf.length > 0) {
    for (const e of value) {
      // eslint-disable-next-line valid-typeof
      if (typeof e !== arrayOf) throw TypeError(`${label} ne contient pas que des éléments de type ${arrayOf}`)
      if (allDef && (e === null || e === undefined)) throw TypeError(`${label} contient un élément null ou undefined`)
    }
  }
}

/**
 * Vérifie que graph est un Graph valide
 * @param {Graph} graph
 * @throws {Error} si c’est pas le cas
 */
export function checkGraphe (graph: Graph | GraphValues): void {
  const g = graph instanceof Graph ? graph : new Graph(graph)
  const { errors } = g.validateSync()
  if (errors.length) throw Error('graphe invalide :\n- ' + errors.join('\n- '))
}

/**
 * Vérifie que value est un number fini
 * @param {number} value Le number à vérifier
 * @param {string} label Pour le message d’erreur éventuel
 * @param {Object} [options]
 * @param {boolean} [options.isInteger] passer true pour vérifier que c’est un entier
 * @param {boolean} [options.isNotZero] passer true pour vérifier que c’est un nombre non nul
 * @param {boolean} [options.isPositive] passer true pour vérifier que c’est un nombre positif
 * @throws {TypeError}
 */
export function checkNumber (value: number, label = 'valeur', {
  isInteger = false,
  isNotZero = false,
  isPositive = false
} = {}): void {
  if (!Number.isFinite(value)) throw TypeError(`${label} n’est pas un nombre fini`)
  if (isInteger && !Number.isInteger(value)) throw TypeError(`${label} n’est pas un nombre entier`)
  if (isPositive && value < 0) throw TypeError(`${label} est un nombre positif`)
  if (isNotZero && value === 0) throw TypeError(`${label} doit être ≠ 0`)
}

/**
 * Vérifie que value est un object non null
 * @param {Object} value L’objet à vérifier
 * @param {string} label Pour le message d’erreur éventuel
 * @throws {TypeError}
 */
export function checkObject (value: Object, label = 'valeur'): void {
  if (typeof value !== 'object' || value == null) throw TypeError(`${label} n’est pas un object non null`)
}

/**
 * Vérifie que value est une string
 * @param {string} value La string à vérifier
 * @param {string} label Pour le message d’erreur éventuel
 * @param {boolean} [notEmpty] passer true pour vérifier que ce n’est pas une string vide
 * @throws {TypeError}
 */
export function checkString (value: unknown, label = 'La valeur', notEmpty = false): void {
  if (typeof value !== 'string') throw TypeError(`${label} n’est pas une string`)
  if (notEmpty && value === '') throw TypeError(`${label} est une string vide`)
}

/**
 * Vérifie que score est un number entre 0 et 1
 * @param {number} score
 * @param {string} [label]
 * @throws {TypeError}
 */
export function checkScore (score: number, label = ''): void {
  if (!Number.isFinite(score) || score < 0 || score > 1) throw TypeError((label.length > 0) ? `${label} n’est pas un score valide : ${String(score)}` : `score ${String(score)} invalide`)
}
