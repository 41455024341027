import type { PlainObject } from 'src/lib/types'

/**
 * Retourne true si ar1 et ar2 sont deux tableaux de même longueur, avec les même valeurs dans le même ordre
 */
export function areSameArray (ar1: unknown[], ar2: unknown[]): boolean {
  if (!Array.isArray(ar1) || !Array.isArray(ar2) || ar1.length !== ar2.length) return false
  return ar1.every((elt, i) => areSameValues(elt, ar2[i]))
}

/**
 * Retourne true si o1 et o2 ont les mêmes propriétés avec les mêmes valeurs
 * @param o1
 * @param o2
 */
export function areSameObject (o1: unknown, o2: unknown): boolean {
  if (typeof o1 !== 'object' || typeof o2 !== 'object') return false
  // les deux sont object
  if (o1 === null) return o2 === null
  // o1 objet non null
  if (o2 === null) return false
  if (Object.keys(o1).length !== Object.keys(o2).length) return false
  return Object.entries(o1).every(([key, value]) => areSameValues((o2 as PlainObject)[key], value))
}

/**
 * Retourne true si v1 et v2 ont la même valeur (égaux pour string|boolean|number, même contenu pour Object|Array)
 * Ne fonctionne pas pour les Object qui ne sont pas des "plain object" comme Regexp, Date, etc.
 */
export function areSameValues (v1: unknown, v2: unknown): boolean {
  // une valeur qui n’est pas égale à elle même en js
  if (typeof v1 === 'undefined') return typeof v2 === 'undefined'
  if (v1 === null) return v2 === null
  if (Number.isNaN(v1)) return Number.isNaN(v2)
  if (Array.isArray(v1)) return Array.isArray(v2) ? areSameArray(v1, v2) : false
  if (typeof v1 === 'object') {
    if (v1 instanceof Date) {
      if (!(v2 instanceof Date)) return false
      return v1.valueOf() === v2.valueOf()
    }
    if (v1 instanceof RegExp) {
      if (!(v2 instanceof RegExp)) return false
      return v1.source === v2.source && v1.flags === v2.flags
    }
    return areSameObject(v1, v2)
  }
  return v1 === v2
}
