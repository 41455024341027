import { formatCheckResult, isLegacyGraph } from 'src/lib/core/checks'
import { convertGraph } from 'src/lib/core/convert1to2'
import type { CheckResults, LegacyGraph, Resultat } from 'src/lib/types'
import { lastElement } from 'src/lib/utils/array'
import { areSameObject } from 'src/lib/utils/comparators'
import { checkArray, checkString } from 'src/lib/utils/validators'
import { fetchEvaluations, sectionExists } from '../core/loadSection'

import Connector from './Connector'
import GraphNode, { GraphNodeActiveSerialized, GraphNodeJson, GraphNodeSerialized, GraphNodeValues } from './GraphNode'
import { DEFAULT_OFFSET } from './Position'

/**
 * Format objet d’un graphe (à mettre dans un json par ex),
 * retourné par graph.serialize()
 */
export interface GraphSerialized {
  nodes: Record<string, GraphNodeSerialized>
  startingId: string
}

export interface GraphJson {
  nodes: Record<string, GraphNodeJson>
  startingId: string
}

// pour le constructeur on est pas obligé de tout fournir (les nodes aussi peuvent être incomplets)
export interface GraphValues {
  nodes?: Record<string, GraphNodeValues>
  startingId?: string
}

interface GraphValidateOpts {
  clean?: boolean
  strict?: boolean
  emptyAllowed?: boolean
}

/**
 * Retourne une 'string' pour décrire le nœud, "nœud {id} ({label})" s’il a un label "nœud {id}" sinon
 * @private
 */
const getNodeInfo = (node: GraphNode): string => `nœud ${node.id}${node.label.length > 0 ? ` (${node.label})` : ''}`

/**
 * Une fonction qui compare un graph avec celui présent dans un resultat.contenu.pathway et retourne true s’il y a compatibilité.
 * Pour l’instant, ne compare que les noms de sections des noeuds du résultat avec ceux des graphNodes d’id identiques.
 * @param {GraphValues | LegacyGraph | undefined} graph
 * @param {Resultat} resultat
 * @return {boolean}
 */
export function compareAlreadyRunWithGraph (graph: GraphValues | LegacyGraph | undefined, resultat: Resultat): boolean {
  // il faut que la partie du graphe déjà exécutée soit identique dans les 2
// - si oui on peut faire une reprise de graphe
// - sinon, il faut afficher un message expliquant que le graphe a changé et qu’on peut pas reprendre où on en était, obligé de repartir du début (cf v1 pour la comparaison)
// pour les comparer, il faut
// - startingId identique
// - partir de ce node startingId (forcément le premier du résultat) et suivre les nodes du résultat :
//   - pour chacun, on doit avoir même section et mêmes paramètres (utiliser isSameObject, si on retombe sur un nœud déjà comparé inutile de recommencer)
// si on est arrivé au dernier node du résultat sans différence, c’est tout bon, on peut faire une reprise de graphe.
  if (graph == null) return false
  graph = isLegacyGraph(graph) ? convertGraph(graph) : graph
  if (graph.nodes == null) throw Error(`Il ny a pas de noeuds dans ce graphe : ${JSON.stringify(graph)}`)
  const results = resultat.contenu.pathway.results
  const resultatGraph = resultat.contenu.pathway.graph
  if (resultatGraph.startingId !== graph.startingId) return false
  if (graph.startingId !== results[0]?.id) return false
  for (const result of results) {
    const id = result.id
    const resultNode = resultatGraph.nodes[id]
    const node = graph.nodes[id]
    if (resultNode == null || node == null) return false
    const section = resultNode.section
    if (section !== node.section) {
      console.info(`section du noeud ${id} différente`)
      return false
    } else {
      const params = (resultNode as GraphNodeActiveSerialized).params
      if (!areSameObject(params, node.params)) {
        console.info(`params du noeud ${id} différents`)
        return false
      }
    }
  }
  return true
}

/**
 * Objet représentant le graphe (sa définition, sans notion de cheminement)
 */
class Graph {
  // en ts il vaut mieux avoir des objets dont les propriétés ne changent pas, si y’a besoin de propriété dynamique pour une collection, Map est plus indiqué
  /** Les nœuds du graphe, indexés par leur id */
  nodes: Record<string, GraphNode>
  /** L’id du nœud de départ du graphe */
  startingId: string

  /**
   * Constructeur minimaliste, qui peut générer un graphe vide invalide (il faudra appeler add puis validate)
   */
  constructor ({ nodes = {}, startingId = '' }: GraphValues = {}) {
    this.nodes = {}
    // ts nous le garanti, mais si on est appelé par du js ça peut être un object, faut un throw ici, c’est au js de nous passer le bon type
    if (typeof nodes !== 'object') throw Error('paramètre nodes incorrect')
    // il faut vérifier que tous les nodes sont des GraphNodes et non pas des GraphNodesSerialized sinon on n’aura pas les méthodes de GraphNode comme isEnd()
    for (const [id, node] of Object.entries(nodes)) {
      this.addNode({ ...node, id })
    }

    if (!startingId) {
      const ids = Object.values(this.nodes).filter(n => !n.isEnd()).map(n => n.id)
      // s’il n’y a qu’un seul nœud non fin on prend son id comme nœud de départ
      if (ids.length === 1) {
        startingId = ids[0] as string
      }
      // sinon faudra le préciser plus tard…
    }
    this.startingId = startingId
  } // constructor

  /**
   * Ajoute un node au graphe
   * @param {Object|GraphNode} node
   * @return {GraphNode} le node ajouté (avec id complété ou modifié si besoin)
   */
  addNode (node: GraphNodeValues): GraphNode {
    const graphNode = node instanceof GraphNode ? node : new GraphNode(node)
    if (graphNode.id) {
      // y'a un id
      if (graphNode.id in this.nodes) {
        // il existe déjà => on le change
        const id = this._getNextId()
        console.warn(Error(`L’id ${graphNode.id} est déjà utilisé dans ce graphe, remplacé par ${id}`))
        graphNode.id = id
      }
    } else {
      // pas d’id, on l'ajoute
      graphNode.id = this._getNextId(graphNode.isEnd() ? 'fin' : 'node')
    }
    // on met un label si y’en avait pas
    if (!graphNode.label) {
      graphNode.label = graphNode.isEnd() ? 'Fin' : this._getNextLabel()
    }
    // on peut ajouter
    this.nodes[graphNode.id] = graphNode
    return graphNode
  }

  /**
   * Ajoute un nœud fin sur node, comme dernier branchement sans condition (décalé en x)
   * @param {GraphNode} node
   */
  addEndNodeAsLastConnexion (node: GraphNode): GraphNode {
    const id = this._getNextId('fin')
    node.addConnector({ target: id, isAlwaysValid: true })
    return this.addNode({
      section: '',
      id,
      label: 'Fin',
      position: { x: node.position.x + DEFAULT_OFFSET, y: node.position.y + DEFAULT_OFFSET }
    })
  }

  /**
   * Vérifie que startingId correspond à un nœud existant (sinon le vide).
   * Ensuite, si startingId est vide et qu'il y a un seul nœud non-fin, alors il est mis dans startingId
   */
  checkStartingId (messages: string[]) {
    if (this.startingId) {
      if (this.nodes[this.startingId] !== null) return // ok
      messages.push(`Le nœud de départ ${this.startingId} n’existe pas => supprimé`)
      this.startingId = ''
    }
    // startingId est vide, si y'a un seul nœud non-fin on le met en startingId
    const candidates = Array.from(Object.values(this.nodes)).filter(n => !n.isEnd())
    if (candidates.length === 0) return
    if (candidates.length === 1) {
      this.startingId = (candidates[0] as GraphNode).id
      messages.push(`Le nœud de départ a été mis sur le seul nœud du graphe ${this.startingId}`)
      return
    }
    // y'en a plusieurs, on prend le premier qui serait target de personne (sinon éventuellement lui-même)
    for (const candidate of candidates) {
      // on regarde les autres
      if (candidates.every(node => node !== candidate && node.connectors.every(connector => connector.target !== candidate.id))) {
        // aucun des autres nœuds ne pointe desuss, c'est un bon candidat
        this.startingId = candidate.id
        messages.push(`Le nœud de départ a été mis sur le premier nœud du graphe qui était cible d’aucun autre ${this.startingId}`)
        return
      }
    }
    // si on est encore là, c'est que tous les nœuds sont target les uns des autres
    if (!this.startingId) {
      const firstCandidate = Object.values(this.nodes).find(n => !n.isEnd)
      if (firstCandidate != null) {
        this.startingId = firstCandidate.id
        messages.push(`Le nœud de départ a été mis sur le premier nœud trouvé dans ce graphe ${this.startingId}`)
      }
    }
  }

  /**
   * Complète le graphe
   * - ajoute un nœud fin s’il n’y en a pas
   * - sur chaque branchement, si le dernier branchement n’est pas sans condition, en ajoute un vers un nœud fin
   * - si startingId est vide on prend le nœud non fin qui n'est pas cible d'autre nœuds (sinon le premier)
   * @return Un message listant les problèmes éventuels, avec l’ajout du nœud fin éventuel, destiné à l’utilisateur qui édite (séparateur \n)
   * @throws {Error} s’il n’y a aucun nœud avec (sans emptyAllowed)
   */
  complete ({ emptyAllowed = false } = {}): string {
    const messages: string[] = []
    this.checkStartingId(messages)
    if (emptyAllowed) {
      // on laisse d'éventuels nœuds fin orphelins
      if (!this.getLength()) return ''
    }

    // y'a des nœuds non-fin et startingId est valide
    const firstNode = this.getNode(this.startingId)

    // La trace du parcours pour getFirstMatch (qui est récursive)
    const getFirstMatchTrace: Set<string> = new Set()

    // Retourne le premier candidat trouvé pour y ajouter un nœud fin
    // (i.e. dont le dernier branchement n’est pas sans condition),
    // en partant de node (donc lui ou un de ses descendants)
    const getFirstMatch = (node: GraphNode): GraphNode => {
      // on ajoute ce node à la trace courante, pour détecter les boucles
      getFirstMatchTrace.add(node.id)
      // cas le plus fréquent du graphe à un nœud sans branchement
      if (!node.isEnd() && node.connectors.length < 1) return node
      const lastConnector = lastElement(node.connectors)
      if (!(lastConnector instanceof Connector)) throw Error('Branchement invalide')
      if (lastConnector.isAlwaysValid) {
        // y’a un dernier branchement sans condition vers un autre nœud
        const targetedNode = this.getNode(lastConnector.target)
        if (getFirstMatchTrace.has(targetedNode.id)) throw Error(`Boucle infinie, en suivant le dernier branchement sans condition de chaque nœud on obtient le parcours ${Array.from(getFirstMatchTrace).join(' -> ')} -> ${targetedNode.id}`)
        return getFirstMatch(targetedNode)
      }
      // sinon on tient notre candidat
      messages.push(`Le nœud ${node.id}${node.label.length > 0 ? ` (${node.label})` : ''} n’avait pas de dernier branchement sans condition, on en ajoute un vers un nœud fin (ajouté également)`)
      return node
    } // getFirstMatch

    /**
     * Ajoute un nœud fin (comme destination d’un nouveau branchement, mis sur le premier nœud trouvé dont le dernier branchement n’était pas sans condition)
     * @inner
     */
    const addEndNode = (): GraphNode => {
      // on va ajouter un nœud fin sur le premier nœud trouvé dont le dernier branchement n’est pas sans condition
      const parentNode = getFirstMatch(firstNode)
      return this.addEndNodeAsLastConnexion(parentNode)
    } // addEndNode

    // le premier nœud fin trouvé (ajouté si besoin)
    const endNode = Array.from(Object.values(this.nodes)).find(n => n.isEnd()) ?? addEndNode()

    // boucle sur les nodes
    let nodeNum = 0
    for (const node of Object.values(this.nodes)) {
      nodeNum++ // démarre à 1
      // ajout du label s’il manque, pas besoin d’unicité…
      if (!node.label) node.label = node.isEnd() ? 'Fin' : `Nœud ${nodeNum}`
      if (node.isEnd()) {
        if (node.connectors.length > 0) {
          messages.push(`Le ${getNodeInfo(node)} est un nœud fin avec des branchements, ils sont supprimés`)
          node.connectors = []
        }
        continue
      }

      // ce n’est pas un nœud fin, on boucle sur ses branchements
      /** l’index du premier branchement sans condition trouvé */
      let alwaysValidIndex = -1
      let rang = 0
      for (const connector of node.connectors) {
        rang++
        // on remplace toutes les target 'fin' par le nœud fin trouvé plus haut
        if (connector.target === 'fin' && this.getNode('fin') == null) connector.target = endNode.id
        // si on est après un branchement sans condition on le signale
        if (alwaysValidIndex >= 0) {
          messages.push(`Attention, le branchement de rang ${rang} du nœud ${node.label} (${node.id}) sera ignoré car celui de rang ${alwaysValidIndex + 1} était sans condition`)
        }
        // si c’est le premier connecteur sans condition on le note
        if (connector.isAlwaysValid && alwaysValidIndex < 0) {
          alwaysValidIndex = rang - 1
        }
      }

      // on est appelé par le validate avec clean: true, faut ajouter cet éventuel connecteur manquant
      if (alwaysValidIndex < 0) {
        node.addConnector({ isAlwaysValid: true, target: endNode.id })
        messages.push(`Le nœud ${node.id} n’avait pas de dernier branchement sans condition (ajouté vers le nœud fin ${endNode.id}`)
      }
    }
    return messages.join('\n')
  }

  /**
   * Retourne le nombre de nœuds "non fin"
   */
  getLength (): number {
    let nbRealNodes = 0
    for (const node of Object.values(this.nodes)) {
      if (!node.isEnd()) nbRealNodes++
    }
    return nbRealNodes
  }

  /**
   * Retourne un nœud du graphe
   * @param nodeId
   * @throws {Error} s’il n’y a pas de nœud avec cet id dans le graphe
   */
  getNode (nodeId: string): GraphNode {
    const node = this.nodes[nodeId]
    if (node == null) throw Error(`Le node d’id ${nodeId} n’existe pas dans le graphe`)
    return node
  }

  serialize (): GraphSerialized {
    const { nodes, startingId } = this
    const nodesSerialized: Record<string, GraphNodeSerialized> = {}
    for (const node of Object.values(nodes)) {
      nodesSerialized[node.id] = node.serialize()
    }
    return { nodes: nodesSerialized, startingId }
  }

  toJSON (): GraphJson {
    const nodes: Record<string, GraphNodeJson> = {}
    for (const node of Object.values(this.nodes)) {
      nodes[node.id] = node.toJSON()
    }
    return { nodes, startingId: this.startingId }
  }

  /**
   * Affecte le nœud de départ du graphe
   * @param startingId
   */
  setStartingId (startingId: string): void {
    if (this.getNode(startingId) == null) throw Error(`Aucun nœud d’id ${startingId} dans ce graphe`)
    this.startingId = startingId
  }

  /**
   * Valide le graphe (version light mais sync, qui vérifie la structure pas la validité des pe)
   * @param {Object} [options]
   * @param {boolean} [options.clean=false] passer true pour virer les nœuds orphelins, ajouter un nœud fin manquant ou un dernier connecteur sans condition par node
   */
  validateSync ({ clean = false, emptyAllowed = false }: GraphValidateOpts = {}): CheckResults {
    const errors: string[] = []
    const warnings: string[] = []

    try {
      if (clean) {
        try {
          const warning = this.complete({ emptyAllowed })
          if (warning) {
            warnings.push(...warning.split('\n'))
          }
        } catch (error) {
          errors.push(error instanceof Error ? error.message : String(error))
        }
      }
      const endIds: string[] = []
      const nodesIds: string[] = []
      for (const node of Object.values(this.nodes)) {
        if (node.isEnd()) endIds.push(node.id)
        else nodesIds.push(node.id)
      }
      if (nodesIds.length === 0) {
        if (emptyAllowed) return formatCheckResult({ warnings: ['graphe vide'] })
        throw Error('Aucun nœud dans ce graphe')
      }

      // on a des nodes non-fin
      if (endIds.length === 0) {
        errors.push('Aucun nœud fin dans ce graphe')
      }
      const firstEndNodeId = endIds[0] ?? ''
      if (!this.startingId) errors.push('Aucun nœud de départ n’est précisé')
      if (!nodesIds.includes(this.startingId)) errors.push(`Le nœud de départ du graphe n’existe pas ${this.startingId}`)

      // liste des nœuds cibles
      const targeted = new Set()

      // boucle sur les GraphNode
      for (const [id, node] of Object.entries(this.nodes)) {
        // test à priori inutile, mais on est pas à l’abri d’un petit malin qui tripote les nodes manuellement
        // @todo les passer privés
        if (!(node instanceof GraphNode)) throw Error('node invalide, il faut passer par addNode() pour ajouter un node')
        const { errors: nodeErrors } = node.validate()
        if (nodeErrors.length) errors.push(...nodeErrors)
        if (node.id !== id) errors.push(`id du node ${id} invalide (${node.id} ≠ ${id}), il faut passer par addNode() pour ajouter un node`)
        const { connectors, section, label } = node
        checkString(label, 'label', true)
        checkString(section, 'section', false) // les nœuds fin ont une section vide

        // nœud fin sans connecteur
        if (node.isEnd()) {
          if (connectors.length > 0) {
            warnings.push(`Le nœud fin ${id} a des branchements sortants, ça n’a pas de sens.`)
          }
          continue
        }
        // c'est pas un nœud fin, la section doit exister
        if (!sectionExists(section)) {
          errors.push(`Nœud ${id} avec une section ${section} qui n’existe pas`)
          continue
        }
        // et il faut vérifier les connecteurs
        checkArray(connectors, 'connectors', { notEmpty: true, allDef: true })
        // boucle sur les branchements
        for (const [index, connector] of connectors.entries()) {
          const rang = index + 1
          const cErrors = connector.getErrors({ errorPrefix: `branchement de rang ${rang} du nœud ${id}` })
          if (cErrors.length) errors.push(...cErrors)
          // on vérifie aussi que le nœud cible existe
          if (this.getNode(connector.target) == null) {
            errors.push(`Le branchement de rang ${rang} du nœud ${getNodeInfo(node)} n’a pas de cible valide (${connector.target})`)
          }
          targeted.add(connector.target)
        }

        // une série de connecteur doit toujours se terminer par un branchement sans condition
        const lastConnector = connectors.at(-1) as Connector // as car on est sûr que c’est pas undefined avec le checkArray ci-dessus
        if (lastConnector.typeCondition !== 'none') {
          const errMsg = `Le dernier branchement sortant d’un nœud doit toujours être sans condition, ce n’est pas le cas pour le nœud ${label}`
          if (clean) {
            node.addConnector({ target: firstEndNodeId, typeCondition: 'none' })
            warnings.push(`${errMsg}, un branchement sans condition vers ${firstEndNodeId} a été ajouté`)
          } else {
            errors.push(errMsg + '.')
          }
        }
      } // boucle nodes

      // vérifie qu’au moins un nœud fin est atteignable
      if (!endIds.some(id => targeted.has(id))) {
        errors.push('Aucun nœud fin n’est atteignable, impossible de terminer ce graphe')
      }

      // vire les nœuds orphelins
      if (clean) {
        for (const id of nodesIds.concat(endIds)) {
          if (id === this.startingId) continue
          if (!targeted.has(id)) {
            errors.push(`Le nœud ${id} n’est pas joignable => supprimé`)
            delete this.nodes[id]
          }
        }
      }
    } catch (error) {
      console.error(error)
      const msg = error instanceof Error ? error.message : String(error)
      errors.push(msg)
    }

    return formatCheckResult({ errors, warnings })
  }

  /**
   * Valide le graphe (version complète async qui vérifie les pe)
   * @param {Object} [options]
   * @param {boolean} [options.clean=false] passer true pour virer les nœuds orphelins, ajouter un nœud fin manquant ou un dernier connecteur sans condition par node
   * @return {string} Message éventuel à propos du nettoyage effectué
   * @throws {Error} si y’a un pb
   */
  async validate ({ clean = false }: GraphValidateOpts = {}): Promise<CheckResults> {
    const { warnings, errors } = this.validateSync({ clean })
    // reste à valider les pe
    for (const node of Object.values(this.nodes)) {
      if (node.isEnd()) continue
      let rang = 0
      for (const connector of node.connectors) {
        rang++
        // si la condition porte sur une pe faut vérifier qu’elle existe
        if (connector.isPeCondition()) {
          const evaluations = await fetchEvaluations(node.section)
          for (const peRef of connector.peRefs) {
            if (!(peRef in evaluations)) {
              warnings.push(`Le connecteur de rang ${rang} du nœud ${node.id} a une condition sur l’évaluation qualitative ${peRef} qui n’existe pas dans la section ${node.section}`)
            }
          }
        }
      }
    }
    return formatCheckResult({ errors, warnings })
  }

  /**
   * Retourne le prochain id utilisable (nodeXX où XX est un entier)
   * @param[prefix=node]
   * @private
   */
  private _getNextId (prefix = 'node'): string {
    let i = 1
    while (this.nodes[`${prefix}${i}`] != null) i++
    return `${prefix}${i}`
  }

  /**
   * Retourne un label par défaut pour le prochain nœud (sans garantir d’unicité)
   * @return {string}
   */
  private _getNextLabel (): string {
    return `Nœud ${this.getLength() + 1}`
  }
}

export default Graph
